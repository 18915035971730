/*-----------------------------------------------------------------------------------

	variables.less

-----------------------------------------------------------------------------------*/


/* ----------------------------------------------------------------
	Base Colors
-----------------------------------------------------------------*/

@theme-color:                   #d57b17;
@body-color:                    #555;
@heading-color:                 #444;


/* ----------------------------------------------------------------
	Line Height Variables
-----------------------------------------------------------------*/

@line-height-base:              1.4;
@line-height-content:           1.6;



/* ----------------------------------------------------------------
	Margins
-----------------------------------------------------------------*/

@margin-base:                   40px;
@margin-small:                  20px;
@margin-big:                    50px;



/* ----------------------------------------------------------------
	Font Families
-----------------------------------------------------------------*/

@body-font:                     'Open Sans', sans-serif;
@heading-font:                  'Merriweather', serif;
@secondary-font:                'Merriweather', serif;



/* ----------------------------------------------------------------
	Font Sizes
-----------------------------------------------------------------*/

@font-size-base:                14px;
@font-size-h1:                  30px;
@font-size-h2:                  24px;
@font-size-h3:                  20px;
@font-size-h4:                  16px;
@font-size-h5:                  @font-size-base;
@font-size-h6:                  12px;


/* ----------------------------------------------------------------
	Layouts
-----------------------------------------------------------------*/


@postcontent:                   74%;
@postcontent-margin:            4%;

@sidebar:                       100% - (@postcontent + @postcontent-margin);
@bothsidebar:                   100% - ( ( @sidebar * 2 ) + ( @postcontent-margin * 2 ) );


@section-padding:				40px;
@section-margin:				40px;
@section-bg:					#F9F9F9;


/* ----------------------------------------------------------------
	Top Bar
-----------------------------------------------------------------*/

@topbar-height:                 45px;
@topbar-line-height:            (@topbar-height - 1);


/* ----------------------------------------------------------------
	Header
-----------------------------------------------------------------*/

@header-height:                 100px;

// Sticky Header
@sticky-header-height:          60px;


// Floating Header Offset
@header-floating-offset:        60px;


// Side Header
@side-header-width:             260px;
@side-header-bg:                #FFF;


/* ----------------------------------------------------------------
	Primary Menu
-----------------------------------------------------------------*/

@primary-menu-padding:				( @header-height - 22 ) / 2;
@sticky-menu-padding:				( @sticky-header-height - 22 ) / 2;

@primary-menu-font:             	@heading-font;
@primary-menu-font-weight:      	bold;
@primary-menu-font-size:        	13px;
@primary-menu-font-tt:          	uppercase;
@primary-menu-font-color:       	#444;
@primary-menu-font-spacing:     	1px;


// Primary Menu Sub Menu
@primary-menu-submenu-width:        220px;
@primary-menu-submenu-font:         @body-font;
@primary-menu-submenu-font-size:    12px;
@primary-menu-submenu-font-weight:  600;


// Primary Mega menu
@mega-menu-title:                   @heading-font;



/* ----------------------------------------------------------------
	Page Title
-----------------------------------------------------------------*/


@page-title-padding:					50px;
@page-title-parallax-padding:			100px;
@page-title-mini-padding:				20px;

@page-title-bg:							#F5F5F5;
@page-title-bg-dark:					#333;

@page-title-size:						28px;
@page-title-subtitle-size:				18px;

@page-title-parallax-size:				40px;
@page-title-parallax-subtitle-size:		22px;

@page-title-mini-size:					18px;


/* ----------------------------------------------------------------
	Portfolio
-----------------------------------------------------------------*/


//Columns Margin
@portfolio-2-margin:            20px;
@portfolio-3-margin:            15px;
@portfolio-4-margin:            12px;
@portfolio-5-margin:            10px;
@portfolio-6-margin:            6px;


//Columns Margin for Sidebar
@portfolio-2-margin-sb:         20px;
@portfolio-3-margin-sb:         15px;
@portfolio-4-margin-sb:         12px;
@portfolio-5-margin-sb:         0;


//Columns Margin for Both Sidebar
@portfolio-2-margin-bs:         20px;
@portfolio-3-margin-bs:         11px;
@portfolio-4-margin-bs:         0;


/* ----------------------------------------------------------------
	Shop
-----------------------------------------------------------------*/

//Columns Margin
@shop-3-margin:            30px;
@shop-4-margin:            20px;


//Columns Margin for Sidebar
@shop-2-margin-sb:         40px;
@shop-3-margin-sb:         25px;


//Columns Margin for Both Sidebar
@shop-2-margin-bs:         40px;


/* ----------------------------------------------------------------
	Blog
-----------------------------------------------------------------*/

//Columns Margin
@blog-2-margin:            40px;
@blog-3-margin:            30px;
@blog-4-margin:            28px;


//Columns Margin for Sidebar
@blog-2-margin-sb:         40px;
@blog-3-margin-sb:         25px;


//Columns Margin for Both Sidebar
@blog-2-margin-bs:         40px;

